import React from "react";
import { Link, useIntl } from "gatsby-plugin-intl";
import { css } from "@emotion/core";
import { mainColor } from "../constants/colors";
import { Desktop, Tablet } from "../constants/responsive";
import NotFound from "../svgs/not-found.svg";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Button from "../components/Button";
import Container from "../components/Container";

const NotFoundPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "404.title" })}
        description={`${intl.formatMessage({
          id: "404.text-1",
        })}${intl.formatMessage({ id: "404.text-2" })}${intl.formatMessage({
          id: "404.text-3",
        })}`}
      />
      <Container>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 60px;
            flex-direction: column;
            ${Tablet} {
              flex-direction: row;
            }
            ${Desktop} {
              padding-top: 100px;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <h1>
              <span
                css={css`
                  color: ${mainColor};
                `}
              >
                {intl.formatMessage({ id: "404.oops" })}
              </span>
              <br
                css={css`
                  display: inline;
                  ${Tablet} {
                    display: none;
                  }
                `}
              />
              {intl.formatMessage({ id: "404.heading" })}
            </h1>
            <h5>
              {intl.formatMessage({ id: "404.text-1" })}
              <br
                css={css`
                  display: none;
                  ${Tablet} {
                    display: inline;
                  }
                `}
              />
              {intl.formatMessage({ id: "404.text-2" })}
              <br
                css={css`
                  display: none;
                  ${Tablet} {
                    display: inline;
                  }
                `}
              />
              {intl.formatMessage({ id: "404.text-3" })}
            </h5>
            <div
              css={css`
                display: flex;
                margin-bottom: 40px;
              `}
            >
              <Link to="/">
                <Button text={intl.formatMessage({ id: "404.home" })} />
              </Link>
            </div>
          </div>
          <div
            css={css`
              width: 200px;
              ${Desktop} {
                width: 300px;
              }
            `}
          >
            <NotFound />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
